export default {
    GetDocsUrl: function (path) {
        // docs only supports english for now
        return "/docs/" + path;

        // return this.GetStaticSiteUrl('docs', path);
    },
    ScrubNonMeaningful: function (input) {
        var nonMeaningfulRegex = /[^a-zA-Z0-9\\.]/g;
        var trimPeriodsRegex = /^\.+|\.+$/g;

        // This is not the same behavior as the C# StringExtension StripLeadingZeros.
        // This will always remove leading zeros. Currently this is helpful since we
        // are using this method to compare a partial part number against scrubbed
        // part numbers coming from the backend as the user types. Thus we do not know
        // if the partial part number would contain an alpha character.
        var stripLeadingZerosRegex = /^0+/;

        input = input.replace(nonMeaningfulRegex, "");
        input = input.replace(trimPeriodsRegex, "");
        input = input.trim();
        input = input.replace(stripLeadingZerosRegex, "");

        return input;
    },
    GetGUID: function () {
        var dt = new Date().getTime();
        var uuid = "xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx".replace(/[x]/g, function () {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return r.toString(16);
        });
        return uuid;
    },
    IsValidEmail: function (email) {
        var re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    IsValidUrl: function (urlString) {
        // It would be better to use URL.canParse, but it only works on Chrome 120 and later.
        // The following is based on the canParse polyfill in core-js
        try {
            return !!new URL(urlString);
            // eslint-disable-next-line no-unused-vars
        } catch (_error) {
            return false;
        }
    },
    IsValidVirtualPath: function (url) {
        var re = /^[a-z0-9-]{1,50}$/;
        return re.test(String(url));
    },
    DateStringToDateInput: function (dateString) {
        if (!dateString) {
            return "";
        }

        var date = new Date(dateString);

        var y = date.getFullYear(),
            m = ("" + (date.getMonth() + 1)).length < 2 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1,
            d = ("" + date.getDate()).length < 2 ? "0" + date.getDate() : date.getDate();

        return y + "-" + m + "-" + d;
    },
    DateInputToDate: function (dateString) {
        var dateParts = dateString.split("-");
        var currentTime = new Date();
        var y = dateParts[0],
            m = dateParts[1],
            d = dateParts[2],
            h = currentTime.getHours(),
            mm = currentTime.getMinutes(),
            ss = currentTime.getSeconds(),
            ms = currentTime.getMilliseconds();

        return new Date(Date.UTC(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10), h, mm, ss, ms)).toISOString();
    },
};
